/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */

 button,
 input,
 optgroup,
 select,
 textarea {
   font-family: inherit; /* 1 */
   font-size: 100%; /* 1 */
   line-height: 1.15; /* 1 */
   margin: 0; /* 2 */
 }
 
 /**
  * Show the overflow in IE.
  * 1. Show the overflow in Edge.
  */
 
 button,
 input { /* 1 */
   overflow: visible;
 }
 
 /**
  * Remove the inheritance of text transform in Edge, Firefox, and IE.
  * 1. Remove the inheritance of text transform in Firefox.
  */
 
 button,
 select { /* 1 */
   text-transform: none;
 }
 
 /**
  * Correct the inability to style clickable types in iOS and Safari.
  */
 
 button,
 [type="button"],
 [type="reset"],
 [type="submit"] {
   -webkit-appearance: button;
 }

body {
  background-color: #e5e5e5;
}
#main {
  display: flex;
  justify-content: center;
  align-items: center;
}
#heading {
  display: flex;
  justify-content: center;
  align-items: center;
}
#container {
  width: 889px;
  height: 500px;
  background-color: #009688;
  display: flex;
  align-items: center;
  flex-direction: column;
}
#container > p {
  font-size: 30px;
  color: aliceblue;
}
#number {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 365px;
  margin-top: 100px;
  margin-right: 102px;
}
#number > #flag {
  margin-right: 20px;
  zoom: 2;
}
#container > button {
  padding: 15px;
  margin-top: 35px;
}

#countries {
  width: 130px;
  padding: 8px;
  font-size: 20px;
  margin-right: 35px;
}
#mobile-number {
  padding: 8px;
  font-size: 19px;
}
#message {
  display: flex; 
  justify-content: center;
  align-items: center; 
}

#message img {
  height: 21px;
}
#message span {
  margin-left: 10px;
  font-size: 20px;
}

footer {
  width: 869px;
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  font-size: 18px;
  background-color: rgba(0, 150, 136, 0.4);
}

@media (max-width: 750px) {
  #container {
    width: 90%;
    height: auto;
    padding: 5%;
  }

  #container > p {
    font-size: 24px;
    color: aliceblue;
    text-align: center;
  }

  #number {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 365px;
    height: 149px;
    margin: 0;
  }
  footer {
    width: 90%;
    padding: 5%;
    text-align: center;
  }
  #number > #flag, #countries {
    margin-right: 0;
  }
  #mobile-number {
    text-align: center;
}
}
